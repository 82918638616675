/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "../src/custom-theme.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.scpa {
  $instance: "scpa";
  $emodal-logo: url(./assets/images/eModal-logo.svg);
  $scpa-logo: url(./assets/images/scpa-logo.svg);
  @include angular-material-theme($scpa-theme);
  @include global-theme($scpa-theme, $scpa-logo, $emodal-logo, $instance);
}

.emodal {
  $instance: "emodal";
  $emodal-logo: url(./assets/images/eModal-logo.svg);
  @include angular-material-theme($emodal-theme);
  @include global-theme($emodal-theme, $emodal-logo, $emodal-logo, $instance);
}

// .sanpedro {
//   $instance: "sanpedro";
//   $emodal-logo: url(./assets/images/eModal-logo.svg);
//   $sanpedro-logo: url(./assets/images/pedro.png);
//   @include angular-material-theme($sanpedro-theme);
//   @include global-theme($sanpedro-theme, $sanpedro-logo, $emodal-logo, $instance);
// }

.genesee{
  $instance: "genesee";
  $emodal-logo: url(./assets/images/eModal-logo.svg);
  $genesee-logo: url(./assets/images/g&w-logo.svg);
  @include angular-material-theme($genesee-theme);
  @include global-theme($genesee-theme, $genesee-logo, $emodal-logo, $instance);
}

.nynj{
  $instance: "nynj";
  $emodal-logo: url(./assets/images/eModal-logo.svg);
  $ptp-logo: url(./assets/images/ptp-logo.png);
  @include angular-material-theme($nynj-theme);
  @include global-theme($nynj-theme, $ptp-logo, $emodal-logo, $instance);
}

.panama{
  $instance: "panama";
  $emodal-logo: url(./assets/images/eModal-logo.svg);
  $panama-logo: url(./assets/images/panama-logo.svg);
  @include angular-material-theme($panama-theme);
  @include global-theme($panama-theme, $panama-logo, $emodal-logo, $instance);
}

.pov{
  $instance: "pov";
  $emodal-logo: url(./assets/images/eModal-logo.svg);
  $pov-logo: url(./assets/images/PoV-logo-appheader.svg);
  @include angular-material-theme($pov-theme);
  @include global-theme($pov-theme, $pov-logo, $emodal-logo, $instance);
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}

#my-splash-screen {
  // background-color: #262626 ;
  background: #15191f url("./assets/images/port-logo.gif");
  background-repeat: no-repeat;
  bottom: 0px;
  left: 0px;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 999999;
  background-position: center;
}

.loading {
  opacity: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #272c33;
  padding-top: 20vh;
  text-align: center;
  z-index: -1;
  transition: opacity 0.8s ease-out;
}

app-root:empty+.loading {
  opacity: 1;
  z-index: 100;
}

.spacer {
  flex: 1 1 auto;
}

// .mat-dialog-container1 {
//   border-radius: 0px !important;
//   padding: 0px !important;
// }

// .mat-button-focus-overlay {
//   background-color: white !important;
// }

// .nav-list-item .cdk-program-focused .mat-button-focus-overlay {
//   display: none !important;
// }

// .mat-menu-panel {
//   min-width: 112px;
//   max-width: 500px !important;
// }

// footer {
//   width: 100%;
//   height: 25px;
//   background: #fafafa;
//   color: white;
//   padding: 0.5rem 0 1rem 0;
//   > * {
//     padding: 0 0 0 2rem;
//   }
//   position: fixed !important;
//   bottom: 0;
// }

// .footer-logo {
//   flex: 1;
//   float: right;
//   padding-right: 10px !important;
// }

// app-dashboard .mat-tab-label {
//   //border-left: 3px solid green !important;
//   min-width: 70px !important;
//   padding: 0px !important;
//   height: 40px !important;
//   opacity: 1 !important;
// }

// app-dashboard .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
//   display: none !important;
// }

app-dashboard .mat-tab-header {
  margin-top: 15px !important;
  margin-left: 15px !important;
}

app-dashboard .tab-content[_ngcontent-c13] {
  margin-top: 15px !important;
  background-color: white !important;
}

app-dashboard .mat-tab-nav-bar,
.mat-tab-header {
  border: none !important;
}

// app-dashboard .mat-tab-label .mat-tab-label-content {
//   justify-content: start !important;
//   width: 100% !important;
//   text-align: left !important;
//   //padding-left: 5px !important;
//   height: 100% !important;
// }

// app-dashboard .mat-tab-label:last-child {
//   //left: 60% !important;
//   border-left: none !important;
// }

app-dashboard .mat-tab-body-content {
  overflow: hidden !important;
}

app-vesselschedule {
  .mat-tab-body-content {
    overflow: auto !important;
  }
  .cal-week-view .cal-all-day-events {
    border-top-style: initial !important;
    border-top-width: 0px !important;
    border-right-style: solid !important;
    border-right-width: 1px !important;
    border-bottom-style: solid !important;
    border-bottom-width: 0px !important;
    border-left-style: solid !important;
    border-left-width: 1px !important;
    height: 480px !important;
  }
}

app-dashboard .mat-tab-heade {
  box-shadow: none !important;
}

.mat-select-panel {
  margin-top: 39px;
}

app-broadcastingemail {
  .mat-checkbox-layout {
    white-space: unset !important;
    color: #272c33 !important;
  }

  .mat-divider {
    border-top-style: unset;
    margin-top: 10px !important;
  }

  .angular-editor-textarea {
    caret-color: black !important;
    color: black !important;
  }

  .mat-input-element {
    color: black !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  app-broadcastingemail {
    .mat-checkbox-layout {
      font-size: 14px !important;
    }

    .mat-checkbox-inner-container {
      height: 14px !important;
      width: 14px !important;
      margin-right: 7px !important;
    }
  }
}



#searchres .mat-table .mat-header-row {
  height: 34px;
}

// .page-header .mat-card-content .mat-focus-indicator {
//   margin:10px 12px 0 0;
//   height: 34px;
//   line-height: 24px;
// }

.page-header .mat-card-content .add-new-trucks
{
  position: relative;
  top: -1px;
}

.emodal .search-results .main-table .threedots {
  display: block;
  margin-right: 15px;
  color: #818181;
}

.emodal .mat-menu-content .mat-menu-item {
  line-height: 40px;
  height: 40px;
}

// .emodal .mat-menu-panel {
//   width: 145px;
// }


.emodal .search-results .main-table th.mat-header-cell {
  background-color: #f0f0f0 !important;
}

.emodal mat-card-title {
  color: #13a0be;
}

.emodal .mat-elevation-z8 {
  -webkit-box-shadow: none;
  box-shadow: none;
}


.emodal .mat-checkbox-checked .mat-checkbox-background
{
  background-color: #00adbb !important;
}

.table td{
  margin:0 15px 20px !important;
}
.table td label{
margin: 0 0 15px;
display: block;
font-size: 14px !important;
color: #a1a1a1;
padding: 0 5px !important;
}

.emodal .mat-table .mat-header-row {
  height: 40px;
}


.emodal .page-header mat-card-content .headertitle , .pov .page-header mat-card-content .headertitle{
  padding:9px 24px 7px 8px !important;
  font-weight:600;
}


.emodal .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #003c5b;
}

.emodal .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(150, 174, 186, 0.70);
}

.emodal mat-row, .emodal mat-header-row, .emodal mat-footer-row, .emodal th.mat-header-cell, .emodal td.mat-cell, .emodal td.mat-footer-cell {
  border-bottom: none;
}


html, body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  overflow: auto;
  scrollbar-width: thin;
  -webkit-scrollbar-width: thin;
  -moz-scrollbar-width: thin;
  -o-scrollbar-width: thin;
}



#main-table tbody td .mat-icon {
  cursor: pointer;
}




  #searchres table tbody tr td:nth-child(4), #searchres table thead tr th:nth-child(4) {
    width: 85px !important;
    left: 0 !important;
  }
  #searchres table tbody tr td:nth-child(5), #searchres table thead tr th:nth-child(5) {
    width: 20% !important;
    left: 0 !important;
  }
  #searchres table tbody tr td:nth-child(6), #searchres table thead tr th:nth-child(6) {
    width: 25%;
    padding:5px 0;
  }


  .emodal .mat-table .mat-row:nth-child(2n) {
    background-color: #fafafa;
  }

  .emodal .mat-table .mat-row:nth-child(4n+3) {
    background-color: transparent;
  }









@media (max-width:1200px)
{
  .search-results table tr td:first-child,.search-results table tr th:first-child {
    width: 15% !important;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 15px !important;
}
}

@media (max-width:992px)
{

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left:8px !important;
}

.pov .mat-table .mat-row .mat-cell {
  font-size: 0.75rem;
}
.pov .search-results .main-table ._select {
  min-width: 42px;
}
.pov .search-results .main-table ._collapse {
  width: 145px;
  min-width: 145px;
}

.pov .search-results .main-table ._collapse:nth-child(3) {
    width: 70px;
    min-width: 70px;
}


#table-container table {
  width:850px !important;
}

}
  @media (max-width:767px)
  {

  .mat-header-cell {
    font-size: 0.72rem;
}

.pov .mat-table .mat-row .mat-cell {
  font-size: 0.72rem;
}

  .status-con
  {
    margin-bottom:20px;
  }

#searchres table tbody tr td:nth-child(6), #searchres table thead tr th:nth-child(6) {
  width:27px;
}

th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
  padding-right:18px !important;
}

.emodal .search-results .main-table ._select {
  width: 40px;
  min-width: 40px;
}

.emodal .page-header
{
  height:100%;
}

.emodal mat-row, .emodal mat-header-row, .emodal mat-footer-row, .emodal th.mat-header-cell, .emodal td.mat-cell, .emodal td.mat-footer-cell {
  padding: 0.10rem .5rem !important;
  white-space: nowrap;
}


#table-container table {
  width:800px !important;
}

.mat-card {
  padding: 10px;
}
 
}

@media (max-width:599px)
{
  .status-con {
  padding: 0 8px !important;
  }

  .method-assigment
  {
    padding: 0 8px !important;
  }
}

  @media (max-width:410px)
  {
    .method-group-btn button.mat-row:first-child{
      margin-top: 10px !important;
    }
    .method-group-btn button
    {
        width:100% !important;
    }
    .method-group-btn
    {
      width:100%!important;
    }
  }



  .mat-custom-error .mat-form-field .mat-form-field-underline {
    border: 1px solid red;
}

.pov .search-results .main-table .threedots {
  float: right;
  display: block;
  margin-right: 10px;
}

.mat-card .mat-table {
  box-shadow: none !important;
}

// .mat-card {
//   padding: 10px !important;
// }
.mat-card {
  padding:7px 7px;
}


.pov .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.cancel-btn .mat-raised-button.mat-button-base {
  background: #fafafa;
  color: #a3a3a3;
  box-shadow: none;
}

.status-con {
  position: relative;
  left: 2px;
}

.pov .search-results .main-table th.mat-header-cell {
  background-color: #f0f0f0;
}

.pov .mat-checkbox-frame {
    border-color: #909090;
}

.pov .page-header mat-card-content .headertitle {
  font-weight: 400 !important;
}

.pov .page-header mat-card-content .btn-export .mat-icon {
  cursor: pointer;
}

.pov .page-header mat-card-content .btn-export
{
  cursor: pointer;
  margin: 0;
  position: relative;
  top: -1px;
}

#truck-details th.mat-header-cell:first-of-type, #truck-details td.mat-cell:first-of-type, #truck-details td.mat-footer-cell:first-of-type {
  padding-left: 5px;
}

.pov .mat-table {
  margin-top: 12px;
}

#table-container .mat-table {
  margin-top:0;
}

.pov .search-results .main-table ._collapse {
  padding: 0 4px;
}


#table-container {
  height: 100% !important;
  padding-bottom: 3px;
  min-height: 185px;
  max-height: 350px;
}

.pov .page-header mat-card-content .btn-actions, .pov .page-header mat-card-content .btn-add {
  margin:6px 0;
  height:35px;
}

.msg_10001{
  color: green !important;
}