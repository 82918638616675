@import "~@angular/material/theming";

$sts-green: #44BC30;
$sts-yellow: #FF9800;
$sts-blue: #0088E8;
$sts-red: #E60F07;
$sts-orangered: orangered;
$sts-black: #212121;
$sts-grey: #757575;
$sts-grey3: #333333;
$sts-greyF: #F3F5F8;

//Primary color
$primary-white: (
  50: #FFFFFF,
  100: #FAFAFA,
  200: #F5F5F5,
  300: #F0F0F0,
  400: #DEDEDE,
  500: #C2C2C2,
  600: #979797,
  700: #818181,
  800: #606060,
  900: #3C3C3C,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
  )
);




/*-----SCPA Color Scheme-----*/
$scpa-palette: (
  50: #E6EBF2,
  100: #BFCCDF,
  200: #97ABC9,
  300: #6E8AB3,
  400: #4C71A4,
  500: #245A97,
  600: #1C528F,
  700: #104883,
  800: #073F76,
  900: #002E5F,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);
/*-----eModal Color Scheme-----*/
$emodal-palette: (
  50: #FFE7E6,
  100: #FFC7B8,
  200: #FFA28A,
  300: #FF795B,
  400: #FF5436,
  500: #FF200C,
  600: #FF1507,
  700: #FF0000,
  800: #F10000,
  900: #DA0000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);

/*-----San Pedro Bay  Color Scheme-----*/
$sanpedro-palette:(
  50:#E0F3EF,
  100: #B3E0D6,
  200: #B3E0D6,
  300: #82CDBD,
  400: #50B9A3,
  500: #2AA990,
  600: #07997E,
  700: #058C71,
  800: #006C54,
  900: #005039,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);

/*-----G&W  Color Scheme-----*/
$genesee-palette:(
  50:#FAFAFA,
  100: #F5F5F5,
  200: #EEEEEE,
  300: #818181,
  400: #BDBDBD,
  500: #9E9E9E,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);


/*-----STS (NYNJ)  Color Scheme-----*/
$nynj-palette:(
  50:#E8F5E6,
  100: #C8E5C2,
  200: #A5D59C,
  300: #81C573,
  400: #66B955,
  500: #4BAC34,
  600: #419E2C,
  700: #358C21,
  800: #297B16,
  900: #0D5C00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);


/*-----Panama  Color Scheme-----*/
$panama-palette:(
  50:#F0F8FF,
  100: #E3F2FE,
  200: #BBDFFD,
  300: #62B6FB,
  400: #51AFFB,
  500: #3EA6FB,
  600: #1997FA,
  700: #1889EB,
  800: #1577D8,
  900: #1267C6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);

/*-----Port Of Virginia  Color Scheme-----*/
$pov-palette:(
  50:#E0F7F8,
  100: #B2ECEE,
  200: #4FD2DA,
  300: #4FD2DA,
  400: #29C8D3,
  500: #00BFCE,
  600: #00AFBB,
  700: #0499A2,
  800: #05858A,
  900: #003C5B,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text
  )
);


