@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "color-palette";
@include mat-core();

$mat-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: white,
  background: white,
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
  divider: 1px solid mat-color($primary-white, 700)
);

@mixin global-theme($theme, $logo, $emodal-logo, $instance) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $sts-green: #44bc30;
  $sts-yellow: #ff9800;
  $sts-blue: #0088e8;
  $sts-red: #e60f07;
  $sts-orangered: orangered;

  .A,
  .CB,
  .CR,
  .CT,
  .CZ,
  .FT,
  .H1,
  .HR,
  .I,
  .LD,
  .N,
  .NF,
  .NP,
  .NT,
  .OA,
  .OD,
  .P1,
  .RL,
  .SA,
  .UR,
  .UV,
  .VA,
  .X1,
  .X6 {
    color: $sts-blue;
  }
  .AE,
  .AL {
    color: $sts-yellow;
  }
  .AV,
  .O,
  .RD {
    color: $sts-green;
  }
  .DN,
  .IB,
  .IR,
  .RI {
    color: $sts-red;
  }
  .success {
    color: $sts-green;
  }
  .pending {
    color: $sts-yellow;
  }
  .mat-button {
      background-color: mat-color($accent, 900);
    
    color: white;
  }
  .mat-input-element {
    caret-color: mat-color($primary-white, 900);
  }

  .mat-toolbar {
    background: mat-color($background, app-bar);
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    max-height: 48px;
    border-bottom: 1px solid map_get($mat-grey, 500);
    //box-shadow: 2px 2px 2px 2px map_get($mat-cyan, A400); //TO-DO(Aslam): Need to Add shadow
  }

  .mat-button {
    background: white;
    color: mat-color($primary-white, 700);
  }

  .mat-focused .mat-form-field-label {
    color: mat-color($primary-white, 900) !important;
  }

  .mat-form-field-ripple {
    background-color: mat-color($accent, 200) !important;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple{
    background-color:$sts-red !important;
  }

  .mat-icon {
    color: mat-color($primary-white, 700);
       vertical-align: middle;
  }

  .mat-icon-button {
    color: mat-color($primary-white, 700);
       vertical-align: middle;
  }
  .mat-icon-button .cdk-focused .cdk-program-focused {
    background: wheat;
  }

  .mat-progress-bar.my-color .mat-progress-bar-fill::after {
    background-color: mat-color($accent, 600) !important;
    height: 3px !important;
  }

  .mat-badge-content {
    color: white !important;
    font-size: 10px;
    top: -7px !important;
    left: 15px;
  }

  .mat-table {
    width: 100%;
    position: relative;
    z-index: 0;
    .mat-header-row {
      height: 48px;
      background: mat-color($primary-white, 300);
      color: mat-color($primary-white, 700);
      font-size: 12px;
    }
    .mat-row {
      height: 44px;
      &.details-row {
        height: 0px;
      }
      .mat-cell {
        color: mat-color($primary-white, 900);
      }
    }
    .mat-row:nth-child(4n + 3) {
      background-color: mat-color($primary-white, 100);
    }
  }

  .search-results {
    overflow: auto;
    margin-top: 2px;
    .main-table {
      width: 100%;
      margin: auto;
      border-collapse: separate;
      border-spacing: 0;
      z-index: 5000;
      //position: relative;
      .tableheadertext {
        font-size: 12px;
        color: mat-color($primary-white, 800);
        font-weight: bold;
      }
      .tablebodytext {
        font-size: 14px;
        color: mat-color($primary-white, 900);
      }
      .gatecode {
        font-size: 10px;
        color: grey;
      }
      .primarycolumn {
        font-weight: 600;
      }
      ._select {
        text-align: left;
        width: 24px;
        min-width: 24px;
      }
      ._collapse {
        text-align: left;
        width: 23px;
        min-width: 23px;
      }
      ._dots {
        width: 2px;
        min-width: 2px;
      }
      .threedots {
        float: right;
        display: none;
      }
      .tablerowcurrency {
        text-align: right;
        padding-left: 0px;
      }
      .currencyinfoinvisible {
        color: transparent;
        float: right;
        padding-right: 10px;
        pointer-events: none;
      }
    }
    .main-table th.mat-header-cell {
      text-align: left;
      background-color: #edeff2;
      border-bottom-style: none;
    }
    .main-table td.mat-cell {
      border-bottom-style: none;
    }
    .main-table tr:hover .threedots,
    .main-table tr:hover ~ .threedots {
      color: mat-color($primary-white, 900);
      display: block;
    }
  }

  snack-bar-container.mat-snack-bar-container {
    &.errorMessage {
      background: map-get($mat-red, 500) !important;
    }
    &.hold {
      background: white !important;
      color: mat-color($primary-white, 900) !important;
      font-size: 12px;
      top: 36.5%;
      left: 6%;
      //height: 42px;
      max-height: 100px;
      position: absolute !important;
      line-height: 21px;
      min-width: 437px;
    }
  }

  button.mat-menu-item {
    background: mat-color($primary-white, 50) !important;
    color: mat-color($primary-white, 900) !important;
  }
  button.mat-menu-item:hover {
    background: mat-color($primary-white, 200) !important;
    color: mat-color($primary-white, 900) !important;
  }
  .noShadow {
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  }
  .page-title {
    font-size: 18px;
    margin: 0px;
    padding: 0px 16px;
    width: 200px;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    align-content: center;
    color: map_get($primary-white, 700);
  }

  .mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 8px -5px rgba(0, 0, 0, 0),
      0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-drawer-content {
    overflow: hidden !important;
  }
  .mat-drawer-backdrop {
    margin-top: 48px;
    background-color: transparent !important;
  }

  .mat-checkbox-checked .mat-checkbox-background {
      background-color: mat-color($accent, 900) !important;
    color: white !important;
  }

  .mat-checkbox-indeterminate .mat-checkbox-background {
      background-color: mat-color($accent, 300) !important;
    color: white !important;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle{
      background-color: mat-color($accent, 900) !important;

  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
      border-color:mat-color($accent, 900) !important;

  }
  
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($primary-white, 900);
  }
  .mat-pseudo-checkbox-checked {
    background-color: mat-color($accent, 900) !important;
    color: white !important;
  }
  .mat-sort-header-arrow {
    color: mat-color($accent, 700);
  }
  .mat-tooltip {
    background: white;
    color: mat-color($accent, 900);
    font-size: 11px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .no-records {
    text-align: center;
    margin-top: 10px;
    color: $sts-orangered;
    width: 100%;
    height: 20px;
    text-align: center;
  }

  mat-tab-group {
    height: 100%;
    width: 100%;
    .mat-tab-header {
      box-shadow: 0px 3px 6px -2px mat-color($primary-white, 600) !important;
      .mat-tab-label-container {
        background: white;
        padding-bottom: 8px;
        .mat-tab-list {
          box-shadow: 0px 2px 6px -2px mat-color($primary-white, 600);
          .mat-tab-labels {
            .mat-tab-label {
              color: mat-color($primary-white, 700);
              padding: 35px 8px !important;
              font-size: 12px !important;
              font-weight: bold;
              &.menus {
                padding: 10px 0px !important;
                font-size: 14px !important;
                font-weight: bold;
              }
            }
            .mat-tab-label-active {
              color: mat-color($accent, 900) !important;
              padding: 35px 8px !important;
              font-size: 12px !important;
              font-weight: bold;
            }
          }
        }
      }
      &.vessel-header {
        height: 48px !important;
        box-shadow: 0px 3px 6px -2px mat-color($primary-white, 600) !important;
        .mat-tab-header .mat-tab-label-active {
          &.vessel-tab-label {
            width: 10%;
            padding: 18px 0px !important;
            font-size: 14px !important;
            font-weight: bold;
          }
        }
      }
    }
  }

  // .mat-tab-label {
  //   color: mat-color($accent, 900);
  //   height: 40px;
  // }
  .mat-ink-bar {
    background-color: mat-color($accent, 700) !important;
  }
  .mat-tab-body-wrapper {
    background: mat-color($primary-white, 100);
    height: 100%;
  }

  .info-icon {
    color: mat-color($accent, 300);
    font-size: 16px;
    cursor: pointer;
    margin-left: 2px;
    vertical-align: middle;
    margin-top: 5px;
  }

  .feeheader{
    font-size: 12px;
    color: mat-color($primary-white, 800);
    font-weight: bold;
  }
  .feedata{
    color: mat-color($accent, 300);
    font-size: 14px;
  }

  .mat-progress-spinner,
  .mat-spinner {
    circle {
      stroke: mat-color($accent, 700);
      // display: inline-block;
    }
  }
  .dialog-footer {
    background: mat-color($primary-white, 100);
  }
  .btn-browse {
    background-color: mat-color($accent, 900);
    color: white;
  }
  .btn-menu {
    height: 47px;
    width: 47px;
    min-width: 47px;
    padding: 0px;
    border-radius: 0px;
    .mat-icon {
      color: map_get($primary-white, 50);
      margin: 0px;
    }
    @if ($instance == "emodal") {
      background-color: map_get($primary-white, 900) !important;
    }
    @else {
      background-color: mat-color($accent, 900);
    }
  }

  .btn-search {
    background-color: mat-color($accent, 900);
    .mat-icon {
      color: map_get($primary-white, 50);
      margin: 0px;
    }
  }

  .btn-submit {
      background-color: mat-color($accent, 900);
    color: white;
  }
  .btn-cancel {
    background: mat-color($primary-white, 100);
    color: mat-color($primary-white, 900);
  }

  .logo {
    background: $logo no-repeat;
    padding-left: 8px;
    padding-right: 40px;
    align-content: center;
    @if ($instance == "emodal") {
      width: 125px;
      margin: 4px 10px;
      height: 25px;
    } 
    @else if($instance == "genesee"){
      width: 100px;
      height: 47px;
    }

    @else if($instance=="nynj"){
      width: 170px;
      height: 40px;
    }
    @else {
    width: 125px;
    margin: 4px 10px;
     height: 40px;
    }
  }

  .app-title {
    margin: 0px;
    padding: 0px;
    color: map_get($primary-white, 800);
  }

  .page-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 56px;
    align-content: center;
    padding: 0px;
    margin: 0px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
    mat-card-content {
      width: 100%;
      .headertitle {
          font-size: 18px;
          padding: 16px 80px 16px 12px;
          min-width: 160px;
          color: mat-color($primary-white, 700);
      }
      .btn-actions,
      .btn-add {
          color: mat-color($primary-white, 1000) !important;
        margin-top: 10px;
        margin-right: 10px;
        height: 34px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btn-export {
        margin: 10px 0px;
        flex-direction: row;
        box-sizing: border-box;
        border: none;
        outline: none;
        height: 34px;
        border: none;
        border-color: rgba(31, 26, 26, 0.12);
        border-radius: 4px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transform: translate3d(0, 0, 0);
        transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        .mat-icon {
          padding: 5px;
          cursor: pointer;
        }
      }
      .custom-view {
        margin-top: 10px;
        float: right;
        margin-left: auto;
        margin-right: 12px;
        height: 34px;
        text-overflow: ellipsis;
        border: none;
        border-color: rgba(31, 26, 26, 0.12);
        border-radius: 4px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transform: translate3d(0, 0, 0);
        transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      .btn-saved-views {
          padding-left: 10px;
          width: 162px;
          height: 34px;
          text-align: left;
          color: mat-color($primary-white, 900);
        &.mat-raised-button {
          border: none;
          border-radius: 0px;
          box-shadow: none;
        }
      }
    }
  }
  .svg-xls-icon {
    color: $sts-green !important;
  }

  footer {
    width: 100%;
    height: 20px;
    background: mat-color($primary-white, 100);
    color: white;
    padding: 0.5rem 0 1rem 0;
    > * {
      padding: 0 0 0 2rem;
    }
    position: fixed !important;
    bottom: 0;
    .eModalLogo{
      position: relative;
      display: inline-flex;
      padding: 0px;
      margin: 0px 10px 0px 0px;
      width: 100px;
      height: 36px;
    }
  }
  .support{
    flex: 1;
    float:left;
    font-size: 12px;
    padding: 12px !important;
    color: mat-color($primary-white,900);
    vertical-align: middle;
  }
  .footer-logo {
    flex: 1;
    float: right;
    padding-right: 10px !important;
  }

  .page-footer {
    width: 100%;
    height: 10px;
    background-color: map_get($primary-white, 100);
    .logo-footer {
      background-image: $logo no-repeat;
      float: right;
    }
  }

  mat-sidenav {
    margin-top: 48px;
    min-width: 320px;
    background: white;
    mat-nav-list {
      line-height: 48px;
      padding: 0px;
      //margin-left: 16px;
      mat-tree {
        border-bottom: map_get($primary-white, 500);
        mat-tree-node {
          color: map_get($primary-white, 900);
          .mat-list-item {
            background: mat-color($primary-white, 200) !important;
          }
          // .mat-list-item:focus {
          //   background: mat-color($accent, 900) !important;
          //   color: mat-color($primary-white, 50) !important;
          // }
          .mat-list-item:hover {
              background: mat-color($accent, 300) !important;
              color: white !important;
          }
          .mat-list-item.link-active {
              background-color: mat-color($accent, 900) !important;
              color: white !important;
          }
        }
      }
    }
    .mat-list-base .mat-list-item {
        color: map_get($primary-white, 900);
        padding-top: 0px !important;
        font-size: 14px;
        font-weight: 600; 
    }
    .menu-l1-title {
        color: map_get($primary-white, 900);
        font-size: 14px;
        font-weight: 600;
        padding-left: 16px;
     
    }

    .menu-l1-title:hover {
        background: mat-color($accent, 900) !important;
        color: white !important;
    }

    .menu-l1-title .menu-icon {
      margin-left: 36px;
    }
    .menu-l1-title .menu-icon:hover {
        color: white !important;
     
    }
    .menu-l1 {
      background: mat-color($primary-white, 200);
      padding-left: 30px;
    }
  }
  .matdivider {
    border-block-end: 1.25px solid mat-color($primary-white, 400);
  }
  .responsemsgs {
    background-color: white;
  }
  .sucessMessage {
    background-color: $sts-green;
    color: white;
  }
  .deleteMessage {
    background-color: $sts-red;
    color: white;
  }
  .failedMessage{
    background-color: $sts-red;
    color: white;
  }
  .mat-simple-snackbar-action {
    color: #002e5f;
  }
  .multilinetooltip {
    white-space: pre-line !important;
  }

  .containerflow{
    color:mat-color($accent, 900);
  }

  .containerflow2{
    color:mat-color($primary-white, 400);
  }

  .dividerflowcolor{
    background-color:mat-color($accent, 900);
  }

  .dividerflowcolor2{
    background-color: #f1f1f1;
  }
  .horizontalconflow{
    background-color: #CCC
  }

  .curr-loc-arr-div > span{
    color: mat-color($accent, 900);
  }

  .flag-full {
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    align-content: center;
    padding-top: 2px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    border-radius: 12px;
    display: inline-block;
    background-color: mat-color($accent, 300);
    color: white;
  }

  .flag-empty {
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    padding-top: 2px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    border-radius: 12px;
    display: inline-block;
    background-color: mat-color($primary-white, 700);
    color: white;
  }

  .padding0 {
    padding: 0px !important;
  }

  .margin8 {
    margin: 8px 8px 0px 8px !important;
  }
  .marginright {
    margin: 8px 0px 0px 8px !important;
  }
  .maxheight {
    max-height: 150px !important;
    overflow: auto;
  }
  .field-label {
    font-size: 12px;
    color: mat-color($primary-white, 700);
    text-align: left;
    width: 100%;
  }

  .field-data {
    font-size: 14px;
    color: mat-color($primary-white, 900);
    text-align: left;
    width: 100%;
  }

  .pointer {
    cursor: pointer;
  }

  .summary-count {
    margin-left: auto;
    table {
      margin-top: 12px;
      margin-right: 20px;
      border: solid 1px mat-color($primary-white, 400);
      border-radius: 16px;
      height: 35px;
      background-color: mat-color($primary-white, 100);
    }
    td {
      padding-left: 10px;
    }
    td:last-child {
      padding-right: 15px;
    }
    td:first-child {
      padding-left: 15px;
    }
    .requestedtag {
      color: mat-color($primary-white, 900);
      font-weight: bold;
      font-size: 16px;
    }
    .successfultag {
      color: $sts-green;
      font-weight: bold;
      font-size: 16px;
    }
    .failedtag {
      color: $sts-red;
      font-weight: bold;
      font-size: 16px;
    }
  }


  .btn-link {
    color: $sts-blue;
    font-weight: bold;
  }

  .btn-link-emodal {
    color: $sts-blue;
    font-weight: bold;
    cursor: pointer;
  }

  .attachmenticon {
    color: mat-color($accent, 700);
  }
  .adjust-flex {
    width: 100%;
    height: 100%;
  }

  mat-card {
    border-radius: 2px !important;
  }
  mat-card-title {
    //margin-top: 6px !important;
    font-size: 14px;
    font-weight: bold;
    color: mat-color($accent, 300);
  }
  .jobtitles {
    margin-top: 6px !important;
    font-size: 14px;
    font-weight: bold;
    color: mat-color($accent, 300);
  }
  .element-detail {
    overflow: hidden;
    width: 103%;
    margin-left: -23px;
  }
  .details-view {
    background: mat-color($primary-white, 200);
    padding: 12px 25px 10px 75px;
    .matcard-main {
      padding: 0px;
    }
  }

  // .footer {
  //   width: 100%;
  //   height: 1%;
  //   background: mat-color($primary-white, 100);
  //   color: white;
  //   padding: 10px;
  //   // > * {
  //   //   padding: 0 0 0 2rem;
  //   // }
  //   position: fixed !important;
  //   bottom: 0;
  //   .footer-logo {
  //     flex: 1;
  //     //background: $emodal-logo no-repeat;
  //     //margin-right: 20px;
  //     float: right;
  //     //width: 62px;
  //     padding-top: 0px;
  //     height: 14px;
  //     //align-items: center;
  //   }
  // }

  .mat-menu-panel {
    &.userinfo {
      width: 360px !important;
      min-width: 360px !important;
      min-height: 130px !important;
      top: 2% !important;
      left: 1% !important;
      padding: 0px !important;
      border-radius: 1px;
      .account-info {
        padding: 0px;
        margin: 0px;
        .details {
          background-color: mat-color($primary-white, 100);
          height: 80px;
          width: 100%;
          //margin: 0px;
          margin-top: -8px;
          .maticoncss {
            margin: 5px;
            font-size: 70px;
            float: left;
          }
          .div-profile {
            padding-left: 85px;
            padding-top: 15px;
            .profilename {
              display: block;
              padding: 4px;
              color: mat-color($accent, 900);
              font-weight: 600;
            }
            .profilemail {
              display: block;
              padding: 4px;
              color: mat-color($primary-white, 700);
            }
          }
        }
        .logoutrow {
          margin-top: 20px;
          .logout-icon {
            color: mat-color($primary-white, 800) !important;
            font-weight: 600 !important;
            font-size: 24px !important;
          }
          span {
            color: mat-color($primary-white, 800) !important;
            // font-weight: 600 !important;
            font-size: 16px !important;
          }
        }
      }
    }
  }
  .container {
    width: 100%;
    margin-top: 1px;
    background: white !important;
    height: 100%;
    .mat-tab-body-wrapper {
      height: 100%;
    }
  }
  .vesselheadercontent {
    background-color: mat-color($accent, 900);
    color: white;
    padding: 20px;
  }
  .vesselbodycontent {
    line-height: 10px;
  }
  .vesseluploadheader {
    background-color: mat-color($primary-white, 200);
    color: mat-color($primary-white, 800);
    padding: 20px;
  }
  .vesseluploadcontent {
    margin: 14px;
  }
  .fee-dialog {
    width: 100%;
    margin-top: 20px;
    .unit-nbr {
      font-weight: bold;
      font-size: 18px;
      color: mat-color($primary-white, 900);
    }
    .date-details {
      width: 100%;
      text-align: left;
      padding: 0px 15px;
      line-height: 1.5em;
      th {
        color: mat-color($primary-white, 600);
        font-size: 12px;
      }
      td {
        color: mat-color($primary-white, 900);
        font-size: 14px;
      }
    }
    .fee-details {
      width: 100%;
      font-size: 14px;
      border: none;
      // tr{
      //   display: inline-flex;
      // }
      tr:nth-child(odd) {
        background-color: mat-color($primary-white, 100);
        line-height: 2em;
      }
      td {
        padding: 2px 0px 0px 2px;
        padding-right: 16px;
        padding-left: 16px;
      }
      .fee-lable {
        min-width: 65%;
        width: 65%;
      }
      .fee-amount {
        min-width: 35%;
        width: 35%;
        text-align: right;
      }
      .isAdded {
        color: mat-color($primary-white, 800);
      }
      .tobeAdded {
        color: mat-color($accent, 300);
      }
      .italic {
        font-style: italic;
      }
      .check-circle {
        color: $sts-green !important;
        padding: 0px 12px;
      }
    }
    .fee-total {
      width: 100%;
      font-size: 14px;
      border: none;
      font-weight: bold;
      padding-block-start: 10px;
      padding-block-end: 10px;
      tr {
        line-height: 1.25em;
      }
      td {
        padding: 2px 0px 0px 2px;
        padding-right: 16px;
        padding-left: 16px;
      }
      .withpaymenticon {
        padding-right: 64px !important;
      }
      .withawaitingicon {
        padding-right: 66px !important;
      }
      .fee-lable {
        min-width: 65%;
        width: 65%;
      }
      .fee-amount {
        min-width: 35%;
        width: 35%;
        text-align: right;
      }
    }
  }
  .slide-in-bck-center {
    -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @keyframes slide-in-bck-center {
    0% {
      -webkit-transform: translateZ(600px);
      transform: translateZ(600px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  .todays-vesselinfo {
    p {
      color: mat-color($primary-white, 800);
      font-weight: bold;
      font-size: 16px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      font-weight: bold;
      color: #436489;
    }
    li {
      text-decoration: none;
      list-style: none;
    }
    .terminalname {
      font-size: 14px;
      width: 100%;
      background: mat-color($primary-white, 100);
      color: mat-color($accent, 300);
      display: flex;
      float: left;
      align-items: center;
      border-radius: 3px;
      padding-bottom: 4px;
    }
    table {
      padding: 0px;
      padding-bottom: 1em;
      margin: 0px;
      font-size: 12px;
      line-height: 1em;
      width: 100%;
      tr {
        line-height: 2em;
      }
      td {
        color: mat-color($primary-white, 900);
        padding: 0px;
        margin: 0px;
      }
      .vessel {
        float: left;
      }
      .voyage {
        float: right;
      }
    }
  }

  .cal-week-view .cal-header.cal-weekend span {
    color: mat-color($primary-white, 800);
  }
  .cal-event-action {
    text-decoration: none;
    color: white;
  }
  .cal-week-view .cal-all-day-events .cal-event {
    line-height: 18px;
    height: 40px;
  }
  .cal-week-view .cal-all-day-events .cal-events-row {
    height: 44px;
  }
  .deletepanelclass .mat-dialog-header {
    background: mat-color($primary-white, 400);
    color: mat-color($primary-white, 900);
  }
  .deletepanelclass .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
  .deletepanelclass .mat-dialog-content {
    margin: 0px !important;
    padding: 20px 46px !important;
  }
  .deletepanelclass .mat-dialog-actions {
    margin: 6px 14px !important;
    padding: 4px !important;
    float: right;
  }
  .assignprioritybackdropclass {
    opacity: 1;
  }
  .assignprioritypanelclass .mat-dialog-container {
    padding: 0px !important;
    overflow-y: auto;
  }
  .assignprioritypanelclass .mat-dialog-content {
    margin: 0px !important;
    padding: 20px !important;
  }
  .assignprioritypanelclass .mat-dialog-actions {
    // background: mat-color($primary-white, 100);
    margin: 0px;
    padding-right: 24px;
  }
  .lfdbackdropclass {
    opacity: 1;
  }
  .payfeebackdropclass {
    opacity: 1;
  }
  .payfeepanelclass .mat-dialog-container {
    padding: 0px !important;
    overflow-y: auto;
  }
  .payfeepanelclass .mat-dialog-content {
    margin: 0px !important;
    padding: 0px !important;
  }
  .payfeepanelclass .mat-dialog-actions {
    background: mat-color($primary-white, 100);
    margin: 0px;
  }
  .uploadvesselbackdropclass {
    opacity: 1;
  }
  .uploadvesselpanelclass .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
  .vesseldetailsbackdropclass {
    opacity: 1;
  }
  .vesseldetailscontainerclass .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
  .preadivcereceiptcontainerclass .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
  .printeirclass .mat-dialog-container {
    padding: 24px !important;
    overflow: hidden;
    //max-height: 0px !important;
  }
  .calendararriving .cal-event {
    background-color: $sts-green !important;
    border-color: $sts-green !important;
  }
  .calendararrived .cal-event {
    background-color: mat-color($accent, 400) !important;
    border-color: mat-color($accent, 400) !important;
  }
  .calendardepartured .cal-event {
    background-color: mat-color($primary-white, 800) !important;
    border-color: mat-color($primary-white, 800) !important;
  }
  .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .arrivingicon {
    color: $sts-green;
  }
  .arrivingspan {
    color: $sts-green;
    font-size: 12px;
    vertical-align: middle !important;
    padding-right: 5px;
  }
  .arrivedicon {
    //color:$sts-orangered;
    color:$sts-red;
    // color: mat-color($accent, 400);
  }
  .arrivedspan {
    color:$sts-red;
    //color:$sts-orangered;
    // color: mat-color($accent, 400);
    font-size: 12px;
    vertical-align: middle !important;
    padding-right: 5px;
  }
  .diparturedicon {
    color: mat-color($primary-white, 800);
  }
  .diparturedspan {
    color: mat-color($primary-white, 800);
    font-size: 12px;
    vertical-align: middle !important;
    padding-right: 5px;
  }
  .cal-week-view .cal-header.cal-today {
    background-color: white !important;
    color: mat-color($accent, 400) !important;
    opacity: 1 !important;
  }
  .cal-week-view .cal-day-headers span {
    font-weight: bold !important;
    font-size: x-large;
    opacity: 1 !important;
  }
  .cal-week-view .cal-day-headers .cal-header {
    color: mat-color($primary-white, 800);
  }
  .cal-week-view .cal-day-headers .cal-header {
    text-align: left;
    padding-left: 10px;
    line-height: 35px;
  }
  .cal-week-view .cal-day-headers .cal-header:hover,
  .cal-week-view .cal-day-headers .cal-drag-over {
    background-color: white !important;
  }
  .mat-calendar-header {
    padding: 0px !important;
  }
  .mat-calendar-controls {
    margin: 0px !important;
    background: mat-color($primary-white, 100);
  }
  .mat-calendar-body-label {
    visibility: hidden;
  }
  .mat-calendar-body-cell-content {
    border-style: none !important;
    border-radius: 0 !important;
  }
  .mat-calendar-body-selected {
    background-color: mat-color($accent, 600) !important;
    border: none;
    color: white;
    font-weight: bold;
  }
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }
  .mat-calendar-body-selected {
    &.mat-calendar-body-cell {
      &tr {
        background: mat-color($primary-white, 300);
      }
    }
  }
  .mat-calendar-body-cell-content {
    border-style: none !important;
    border-radius: 0 !important;
  }

  

  .mat-calendar-table-header {
    color: mat-color($primary-white, 900) !important;
    th {
      font-weight: bold !important;
      font-size: 12px;
    }
  }
  .mat-calendar-period-button {
    color: mat-color($accent, 300) !important;
    background: transparent;
  }
  .mat-calendar-content {
    padding: 18px 8px 8px 8px;
  }
  .div_scroll {
    height: 87vh;
  }
  .warn-icon {
    color: $sts-red !important;
  }
  .warn-message {
    color: mat-color($primary-white, 900);
  }
  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0 0 30px;
    position: relative;
    height: inherit;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8d8a8a;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555555;
  border-radius: 5px;
}

$scpa-theme: mat-light-theme(mat-palette($primary-white, 50), mat-palette($scpa-palette, 900));
$emodal-theme: mat-light-theme(mat-palette($primary-white, 50), mat-palette($emodal-palette, 900));
// $sanpedro-theme: mat-light-theme(mat-palette($primary-white, 50), mat-palette($sanpedro-palette, 900));
$genesee-theme:mat-light-theme(mat-palette($primary-white, 50), mat-palette($genesee-palette, 900));
$nynj-theme:mat-light-theme(mat-palette($primary-white, 50), mat-palette($nynj-palette, 900));
$panama-theme:mat-light-theme(mat-palette($primary-white, 50), mat-palette($panama-palette, 900));
$pov-theme:mat-light-theme(mat-palette($primary-white, 50), mat-palette($pov-palette, 900));
.mat-button-toggle-label-content {
  line-height: 36px !important;
}
.fixed-toolbar {
  position: fixed;
  top: 0px;
  z-index: 1;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 10px 5px rgba(0, 0, 0, 0.35)
}
.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden !important;
}

